export const artData = [
  {
    title: "Marsh",
    src: "swamp2.jpeg",
    dimensions: "18x24 in",
    material: "Oil on Panel",

  },
  {
    title: "Golden Hour",
    src: "golden_hour.jpg",
    dimensions: "18x24 in",
    material: "Oil on Panel",
  },
  {
    title: "Terra",
    src: "terra.jpg",
    dimensions: "18x24 in",
    material: "Oil on Panel",
  },
  {
    title: "Nix",
    src: "nix.jpeg",
    dimensions: "18x24 in",
    material: "Oil on Panel",
  },
  {
    title: "North Woods Reflection",
    src: "north_woods_reflection.jpeg",
    dimensions: "18x24 in",
    material: "Oil on Panel",
  },
  {
    title: "Shohola Falls 1",
    src: "shohola_falls_1.jpeg",
    dimensions: "18x24 in",
    material: "Oil on Panel",
  },
  {
    title: "Shohola Falls 2",
    src: "shohola_falls_2.jpg",
    dimensions: "18x24 in",
    material: "Oil on Panel",
  },
  {
    title: "Lone Oak Trailhead NH",
    src: "romans_nose.jpeg",
    dimensions: "18x24 in",
    material: "Oil on Panel",
  },
  {
    title: "North Woods Creek",
    src: "north_woods_creek.jpeg",
    dimensions: "24x30 in",
    material: "Oil on Panel",
  },
  {
    title: "Waldeinsamkeit",
    src: "waldeinsamkeit.jpeg",
    dimensions: "24x30 in",
    material: "Oil on Panel",
  },
  {
    title: "Orchard",
    src: "great_lawn.jpeg",
    dimensions: "24x30 in",
    material: "Oil on Panel",
  },
  {
    title: "North Woods Tree",
    src: "north_woods_tree.jpeg",
    dimensions: "24x30 in",
    material: "Oil on Panel",
  },
  {
    title: "Saint Francis",
    src: "saint_francis.jpeg",
    dimensions: "24x30 in",
    material: "Oil on Panel",
  },
  {
    title: "Twig",
    src: "north_woods_twiggy.jpeg",
    dimensions: "8x10 in",
    material: "Oil on Pnael",
  },
  {
    title: "Polar Caves NH",
    src: "polar_caves_nh.jpeg",
    dimensions: "24x30 in",
    material: "Oil on Panel",
  },
  {
    title: "Wave Columns",
    src: "wave_columns.jpeg",
    dimensions: "9x12 in",
    material: "Laminate, Plywood, Lacquer",
  },
  {
    title: "Into Thick Air",
    src: "into_thick_air.jpeg",
    dimensions: "12x16 in",
    material: "Plywood, Matte Medium",
  },
  {
    title: "Battery",
    src: "battery.jpeg",
    dimensions: "9x12 in",
    material: "Laminate, Plywood, Ash, Lacquer",
  },
  {
    title: "Object",
    src: "object_8.jpeg",
    dimensions: "8x10 in",
    material: "Laminate, Plywood, Oak, Lacquer",
  },
  {
    title: "Blinky",
    src: "blinky.jpeg",
    dimensions: "22x28 in",
    material: "Laminate, Plywood, Oak, Lacquer",
  },
  {
    title: "Folding Panel 3",
    src: "foldpanel.JPG",
    dimensions: "30x40 in",
    material: "Laminate, Plywood, Lacquer",
  },
  {
    title: "Folding Panel 2",
    src: "niceeaster.JPG",
    dimensions: "12x13 in",
    material: "Laminate, Plywood, Lacquer",
  },
  {
    title: "Enterprise Panel",
    src: "enterprize.JPG",
    dimensions: "14x13 in",
    material: "Laminate, Plywood, Lacquer, Cardboard",
  },
  {
    title: "Folding Panel",
    src: "tallfoldy.JPG",
    dimensions: "9x14 in",
    material: "Laminate, Plywood, Lacquer",
  },
  {
    title: "Wave Panel",
    src: "wavy.JPG",
    dimensions: "7x10 in",
    material: "Laminate, Plywood, Lacquer, Oak",
  },
  {
    title: "Toast",
    src: "toast.JPG",
    dimensions: "7x10 in",
    material: "Laminate, Plywood, Lacquer, Oak",
  },
];
